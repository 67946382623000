<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 occupancy-screen occupancy-settings gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_occupancy-help-online-settings'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy.header.settings") }}
        </div>
        <template v-slot:right>
          <button @click="saveSettings">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section v-if="settingsLoaded">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="useDemoData"
                    type="checkbox"
                    v-model="settingsObject.demo"
                  />
                  <label for="useDemoData"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("occupancy.settings.use-demo-data") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item prediction-days">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl">
              <dt class="clebex-item-dt">
                {{ displayLabelName("occupancy.settings.prediction-days") }}
              </dt>
              <dd class="clebex-item-dd">
                <div class="clebex-section-input">
                  <input
                    name="title"
                    as="input"
                    rules="required"
                    type="number"
                    min="0"
                    id="predictionDays"
                    v-model="settingsObject.prediction_number"
                  />
                </div>
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item prediction-days">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl">
              <dt class="clebex-item-dt">
                {{
                  displayLabelName("occupancy.settings.no-communication-days")
                }}
              </dt>
              <dd class="clebex-item-dd">
                <div class="clebex-section-input">
                  <input
                    name="title"
                    as="input"
                    rules="required"
                    type="number"
                    min="0"
                    id="noCommunicationDays"
                    v-model="settingsObject.no_communication_days"
                  />
                </div>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ displayLabelName("occupancy.settings.report-colors") }}
              </label>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border color-picker">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "occupancy.settings.min-average-max-report-color"
                )
              }}</label>
            </div>
            <span class="follow-up-icon-item">
              <input
                @change="setColor($event.target.value, 'min_max_report')"
                type="color"
                :value="settingsObject.min_max_report"
              />
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border color-picker">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "occupancy.settings.historical-occupancy-report-color"
                )
              }}</label>
            </div>
            <span class="follow-up-icon-item">
              <input
                @change="
                  setColor($event.target.value, 'historical_occupancy_report')
                "
                type="color"
                :value="settingsObject.historical_occupancy_report"
              />
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border color-picker">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "occupancy.settings.historical-real-use-report-color"
                )
              }}</label>
            </div>
            <span class="follow-up-icon-item">
              <input
                @change="
                  setColor($event.target.value, 'historical_real_use_report')
                "
                type="color"
                :value="settingsObject.historical_real_use_report"
              />
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border color-picker">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "occupancy.settings.prediction-day-report-color"
                )
              }}</label>
            </div>
            <span class="follow-up-icon-item">
              <input
                @change="setColor($event.target.value, 'prediction_day_report')"
                type="color"
                :value="settingsObject.prediction_day_report"
              />
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border color-picker">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "occupancy.settings.prediction-weekday-report-color"
                )
              }}</label>
            </div>
            <span class="follow-up-icon-item">
              <input
                @change="
                  setColor($event.target.value, 'prediction_weekday_report')
                "
                type="color"
                :value="settingsObject.prediction_weekday_report"
              />
            </span>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <span class="label">
              {{ displayLabelName("occupancy.settings.oldest-record") }}
            </span>
            <span class="follow-up-icon-item">
              {{ displayDate(settingsObject.first_record) }}
              {{ displayTime(settingsObject.first_record) }}
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <span class="label">
              {{ displayLabelName("occupancy.settings.latest-record") }}
            </span>
            <span class="follow-up-icon-item">
              {{ displayDate(settingsObject.last_record) }}
              {{ displayTime(settingsObject.last_record) }}
            </span>
          </div>
        </li>
      </ul>
      <ul v-if="isOutdated" class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <span class="label">
              {{ displayLabelName("occupancy.settings.issues-fetching") }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-warning-red" width="18" height="18" />
              </span>
            </span>
          </div>
        </li>
      </ul>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters, mapState } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OccupancySettings",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      predictionDays: 0,
      settingsObject: {
        demo: true,
        prediction_number: 0,
        no_communication_days: 0,
        min_max_report: "#BF328D",
        prediction_day_report: "#24B7AF",
        prediction_weekday_report: "#24B7AF",
        historical_occupancy_report: "#24B7AF",
        historical_real_use_report: "#24B7AF",
        last_record: null,
        first_record: null
      },
      settingsLoaded: false,
      helpSlug: "occupancy-settings"
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("occupancy", ["settings", "isOutdated"])
  },
  created() {
    this.$store.commit("loader/setScreenLoading", true, { root: true });
    this.getSettings().finally(() => {
      if (this.settings) {
        this.settingsObject = this.settings;
        this.settingsObject.demo = this.settingsObject.demo === 1;
      }
      this.settingsLoaded = true;
      this.$store.commit("loader/setScreenLoading", false, { root: true });
    });
  },
  methods: {
    ...mapActions("occupancy", ["getSettings", "setSettings"]),
    saveSettings() {
      this.setSettings(this.settingsObject).finally(() => {
        this.getSettings().finally(() => {
          if (this.settings) {
            this.settingsObject = this.settings;
            this.settingsObject.demo = this.settingsObject.demo === 1;
          }
          this.settingsLoaded = true;
        });
      });
    },
    setColor(color, section) {
      this.settingsObject[section] = color;
    },
    displayDate(date) {
      if (!date) {
        return "";
      }
      return formatDate(DateTime.fromISO(date), this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return "";
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  }
};
</script>
